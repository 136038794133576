import { SVGProps, memo } from "react";

function Apple(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 13 16" fill="none" {...props}>
            <path
                d="M6.693 3.692c.721 0 1.625-.488 2.163-1.139.488-.59.843-1.414.843-2.238C9.7.203 9.69.092 9.67 0c-.803.03-1.767.54-2.346 1.22-.457.52-.874 1.333-.874 2.167 0 .122.02.244.03.285.051.01.133.02.214.02zM4.153 16c.986 0 1.423-.661 2.652-.661 1.249 0 1.523.64 2.62.64 1.077 0 1.798-.996 2.478-1.973.762-1.119 1.077-2.217 1.097-2.268-.071-.02-2.133-.864-2.133-3.234 0-2.055 1.625-2.98 1.717-3.052-1.077-1.546-2.712-1.587-3.159-1.587-1.209 0-2.194.733-2.813.733-.67 0-1.554-.692-2.6-.692C2.022 3.906 0 5.554 0 8.666c0 1.933.752 3.977 1.676 5.3C2.468 15.085 3.159 16 4.154 16z"
                fill="#fff"
            />
        </svg>
    );
}

const MemoApple = memo(Apple);
export default MemoApple;
