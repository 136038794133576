import { SVGProps, memo } from "react";

function Google(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 20 22" fill="none" {...props}>
            <g clipPath="url(#prefix__clip0_942_7699)">
                <path
                    d="M20 11.227c0-.84-.068-1.451-.215-2.086h-9.58v3.786h5.623c-.114.941-.726 2.359-2.086 3.311l-.02.127 3.03 2.347.21.02C18.888 16.953 20 14.334 20 11.228z"
                    fill="#4285F4"
                />
                <path
                    d="M10.204 21.204c2.755 0 5.068-.907 6.757-2.472l-3.22-2.494c-.861.601-2.018 1.02-3.537 1.02-2.698 0-4.989-1.78-5.805-4.24l-.12.01-3.15 2.438-.04.115a10.197 10.197 0 009.115 5.623z"
                    fill="#34A853"
                />
                <path
                    d="M4.399 13.018A6.282 6.282 0 014.06 11a6.6 6.6 0 01.329-2.018l-.006-.135-3.19-2.477-.104.05A10.214 10.214 0 000 11c0 1.644.397 3.197 1.088 4.58L4.4 13.019z"
                    fill="#FBBC05"
                />
                <path
                    d="M10.204 4.741c1.916 0 3.209.828 3.946 1.52l2.88-2.812C15.26 1.805 12.96.796 10.203.796c-3.99 0-7.438 2.29-9.116 5.623l3.3 2.563c.827-2.46 3.118-4.24 5.816-4.24z"
                    fill="#EB4335"
                />
            </g>
            <defs>
                <clipPath id="prefix__clip0_942_7699">
                    <path fill="#fff" transform="translate(0 .767)" d="M0 0h20v20.467H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}

const MemoGoogle = memo(Google);
export default MemoGoogle;
