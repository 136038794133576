import { SVGProps, memo } from "react";

function Meta(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 20 14" fill="none" {...props}>
            <path
                d="M2.16 9.119c0 .763.168 1.35.387 1.704.287.465.716.662 1.152.662.564 0 1.08-.14 2.072-1.513.796-1.101 1.734-2.647 2.364-3.616l1.069-1.641c.742-1.14 1.6-2.407 2.585-3.266.805-.701 1.672-1.09 2.545-1.09 1.465 0 2.861.848 3.93 2.441C19.432 4.544 20 6.742 20 9.01c0 1.348-.266 2.338-.718 3.12-.436.757-1.288 1.514-2.72 1.514v-2.158c1.226 0 1.532-1.127 1.532-2.417 0-1.837-.428-3.876-1.372-5.333-.67-1.034-1.538-1.665-2.493-1.665-1.032 0-1.864.779-2.798 2.167-.496.738-1.006 1.638-1.579 2.652l-.63 1.117c-1.265 2.245-1.586 2.755-2.219 3.599-1.11 1.477-2.057 2.037-3.304 2.037-1.479 0-2.414-.64-2.994-1.606C.232 11.25 0 10.217 0 9.041l2.16.078z"
                fill="#0081FB"
            />
            <path
                d="M1.703 2.952c.99-1.527 2.42-2.594 4.06-2.594.949 0 1.893.28 2.878 1.085 1.078.88 2.227 2.33 3.66 4.717l.514.857c1.241 2.067 1.947 3.13 2.36 3.632.532.644.904.836 1.387.836 1.226 0 1.532-1.127 1.532-2.417L20 9.008c0 1.349-.266 2.339-.718 3.121-.436.757-1.288 1.513-2.72 1.513-.89 0-1.679-.193-2.551-1.016-.67-.632-1.454-1.753-2.057-2.762L10.16 6.868c-.9-1.504-1.726-2.625-2.203-3.133-.514-.546-1.175-1.205-2.23-1.205-.853 0-1.578.599-2.184 1.515l-1.84-1.093z"
                fill="url(#prefix__paint0_linear_942_7712)"
            />
            <path
                d="M5.727 2.53c-.853 0-1.578.599-2.184 1.515C2.685 5.339 2.16 7.267 2.16 9.118c0 .764.167 1.35.386 1.705L.705 12.036C.232 11.249 0 10.217 0 9.041c0-2.139.587-4.368 1.703-6.089.99-1.527 2.42-2.594 4.06-2.594L5.726 2.53z"
                fill="url(#prefix__paint1_linear_942_7712)"
            />
            <defs>
                <linearGradient
                    id="prefix__paint0_linear_942_7712"
                    x1={4.336}
                    y1={6.656}
                    x2={18.107}
                    y2={7.351}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0064E1" />
                    <stop offset={0.4} stopColor="#0064E1" />
                    <stop offset={0.83} stopColor="#0073EE" />
                    <stop offset={1} stopColor="#0082FB" />
                </linearGradient>
                <linearGradient
                    id="prefix__paint1_linear_942_7712"
                    x1={2.881}
                    y1={10.025}
                    x2={2.881}
                    y2={4.948}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0082FB" />
                    <stop offset={1} stopColor="#0064E0" />
                </linearGradient>
            </defs>
        </svg>
    );
}

const MemoMeta = memo(Meta);
export default MemoMeta;
